body {
  background: #f3f5f9;
  font-family: 'Source Sans 3', sans-serif;
}
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
header {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;
  padding: 0 30px;
  align-items: center;
  box-sizing: border-box;
}
.chat {
  width: calc(100% - 40px);
  margin: 20px auto 20px auto;
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  height: calc(100% - 30px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat__container {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 320px);
}
.chat__message {
  height: 70px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.chat__message-field {
  background: #f3f5f9;
  border-radius: 15px;
  border: 0;
  padding: 0px 30px;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #696c80;
  flex-grow: 1;
  margin-right: 20px;
}
.chat__message-field input {
  background: #f3f5f9;
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
  outline: none !important;
  font-size: 20px;
  line-height: 28px;
}
.chat__message-field input:disabled {
  opacity: 0.5;
  cursor: default;
}
.chat__message button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  background: #ffa339;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}
.chat__message button:disabled {
  background: #ffd09a;
  cursor: default;
}
.chat__message button:disabled img {
  opacity: 0.5;
}
.chat__message button img {
  position: absolute;
  left: 10px;
  top: 13px;
}

.system-questions {
  background-color: #f3f5f9;
  border-radius: 30px;
  padding: 25px;
  margin-bottom: 20px;
}
.system-questions__heading {
  display: flex;
  align-items: center;
}
.system-questions__heading img {
  width: 25px;
  height: 25px;
}
.system-questions__heading span {
  font-weight: 600;
  display: block;
  font-size: 18px;
  margin-left: 15px;
}
.system-questions__questions {
  padding-left: 40px;
}
.questions__question {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  border-radius: 10px;
  background: #4e426d;
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 12px;
  cursor: pointer;
}
.questions__question:hover {
  opacity: 0.9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.system-question__user {
  background: #4e426d;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.system-question__user img {
  margin-right: 10px;
}
.system-question__container {
  background: #ffffff;
  padding: 16px 32px;
  margin-left: 50px;
}
.system-question__container li {
  margin-bottom: 5px;
  line-height: 20px;
}

























.lds-ring {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
  left: -6px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  margin: 0px;
  border: 10px solid #ffa339;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa339 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}